$primary: #F9C23C;


.isp-card{
    position: relative;
    
    &.showRegionDropdown{
        .region-selector-dropdown{
            display: block;
        }
        .blur-overlay {
            display: block;
            background-color: #F7F7F866;
            backdrop-filter: blur(4px);
        }

        .region-selector{

            background-color: #D9D9D933;
            border-radius: 7px 7px 0 0;
        }
    }

    .blur-overlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        backdrop-filter: blur(0px);
        z-index: 4;
        pointer-events: none;
    }

    .region-selector-wrapper{
        background-color: #FFFFFF;
        z-index: 5;

    }

    .region-selector{
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        border: 1px solid #E3E5E9;
        margin: 0;
        border-radius: 7px;
        padding: 7px;
        box-sizing: border-box;
        cursor: pointer;
        background-color: #FFFFFF;

        .proxy-card-title{
            font-size: 24px;
            margin: 0;
        }

        .arrow-container{
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            svg{
                width: 12px;
            }
        }

        .icon-container{
            min-width: 24px;
            max-width: 24px;
            min-height: 24px;
            max-height: 24px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                min-width: 14px;
                max-width: 14px;
                min-height: 14px;
                max-height: 14px;
                object-fit: contain;
            }
        }

        &-label{
            padding: 18px 24px 0px 24px;
            text-transform: capitalize;
            font-size: 14px;
            font-family: "Inter";
            font-weight: 500;
            color: #404040;
            margin-bottom: 5px;
        }

        &-dropdown{
            position: absolute;
            top: 100%;
            left: -1px;
            width: 100%;
            display: none;
            background-color: #FFFFFF;
            border: 1px solid #E3E5E9;
            border-radius: 0 0 7px 7px;
            box-sizing: content-box;
            cursor: pointer;
            z-index: 2;

            &-item{
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                padding: 11px 8px;
                background-color: #FFFFFF;
                cursor: pointer;

                &:hover{
                    background-color: #D9D9D933;
                }

                &:nth-of-type(2){
                    padding: 0 8px;
                    position: relative;
                    &::before {
                        content : "";
                        position: relative;
                        display:block;
                        clear:both;
                        width: 100%;
                        height  : 11px;
                        border-top:1px solid #E3E5E9;
                        margin: 0px ;
                    }
                    &::after {
                        position: relative;
                        display:block;
                        clear:both;
                        content : "";
                        width: 100%;
                        height  : 11px;
                        border-bottom:1px solid #E3E5E9;
                        margin: 0px;
                    }
                }

                &-flag{
                    width: 25px;
                    height: 17px;
                    margin-right: auto;
                }
            }
        }
    }

    .proxy-card-header{
        padding-top: 0px;
    }
    

    .details-row{
        svg{
            width: 16px;
            height: 16px;
            color: #404040;
        }
        &.details-row-highlighted {
            svg{
                color: #000000;
            }
            span{
                font-weight: bold;
            }
        }

        img.flag-icon{
            width: 22px!important;
            height: unset!important;
            margin-left: 8px;
        }
    }
}