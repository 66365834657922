.custom-slider {
    margin: auto;
}

.custom-slider-track {
    top: 3px;
    height: 6px;
    background: #F7F7F8;
}

.custom-slider-thumb {
    cursor: pointer;
    background: #F7F7F8;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    outline: none;
    border: 4px solid #F9C23C;
}